.dataFill {
    margin: 5px;
    display: flex;
}

.w-10 {
    width: 10%;
}

@media only screen and (max-width: 600px) {
    .custom {
        justify-content: space-around;
    }
}