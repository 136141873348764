.abacus {
    display: flex;
    flex-direction: row;
}

.b-left {
    border-left: var(--abacus-border-width) solid black;
}

.b-bottom {
    border-bottom: var(--abacus-border-width) solid black;
}

.b-right {
    border-right: var(--abacus-border-width) solid black;
}

.b-top {
    border-top: var(--abacus-border-width) solid black;
}

.mmmmargin {
    margin: 5px;
}