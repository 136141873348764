:root {
  --abacus-border-width: 3px;
  --xs: 0px;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.no-img-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.noNumArrow::-webkit-outer-spin-button, .noNumArrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noNumArrow {
  -moz-appearance: textfield;
}

.textCenter {
  text-align: center;
}

.md-only {
  display: none !important;
}

.md-up {
  display: none !important;
}

@media only screen and (min-width: 576px) {
  .sm-only {
    display: none !important;
  }
  .md-only {
    display: block !important;
  }
  .md-up {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) {
  .md-only {
    display: none !important;
  }
}