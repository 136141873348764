/* ABACUS SPACING ETC */
.upper {
    position: relative;
}

.divider {
    border-bottom: var(--abacus-border-width) solid black;
    width: 100%;
}

.lower {
    position: relative;
}


/* UTILITY CLASSES */
.verticalLine {
    position: absolute;
    left: 50%;
    z-index: -1;
    border-right: 1px solid #42a5f5;
    border-left: 1px solid #42a5f5;
    height: 100%;
    width: 0px;
}

.bead {
    cursor: pointer;
}

.abcdefgAnimbead {
    display: block;
}