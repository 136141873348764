.operBox {
    margin-left: 10px;
    flex-direction: column;
    width: 100%;
}

.no2 {
    margin-top: 5px;
}

.fw {
    width: 100%;
}

.md-only {
    display:none;
}


@media only screen and (min-width: 576px) {
    .operBox {
        width: 50%;
    }
    .md-only{
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    .md-only{
        display:none;
    }
    .operBox {
        width: 15%;
    }
}