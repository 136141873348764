.navbar {
    height: 3em;
    align-items: center;
    background: #f8f9fa;
    padding: 5px;
}

.navItem {
    text-decoration: none;
    color: black;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}